import React from 'react';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { func, shape, string, bool } from 'prop-types';

import { FormattedMessage } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import { parse } from '../../../util/urlHelpers';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../context/configurationContext';

import css from './LandingPageSearchSection.module.css';
import LocationSearchForm from './LocationSearchForm/LocationSearchForm';
import { defaultLocation } from '../../../config/configExtraLocation';

const LandingPageSearchSection = props => {
  const { currentSearchParams } = props;

  const config = useConfiguration();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();
  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const handleSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.maps.sortSearchByDistance ? { origin } : {};
    const region = search.split(',')[0].toLowerCase();
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_region: region === 'all' ? defaultLocation : region,
      bounds,
      sort: 'meta_sRating,meta_reviewCount',
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };
  const locationFieldsPresent = config.maps.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;

  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };
  return (
    <>
      <section className={css.root}>
        <div className={css.videoContainer}>
          <video
            className={css.videoBgLanding}
            loop
            muted={true}
            autoPlay={true}
            playsInline={true}
            id="rareWatersBgVideo"
            src="/static/media/background-video_1.mp4"
          ></video>
          <div className={css.opacityImg}></div>
        </div>
        <div className={css.sectionContent}>
          <div className={css.opacityImg}></div>
          <div className={css.bgImgContent}>
            <h3 className={css.title}>
              <FormattedMessage id="LandingPageSearchSection.title" />
            </h3>
            <h5 className={css.subTitle}>
              <FormattedMessage id="LandingPageSearchSection.subTitle" />
            </h5>
            <LocationSearchForm onSubmit={handleSubmit} initialValues={initialSearchFormValues} />
          </div>
        </div>
      </section>
    </>
  );
};

LandingPageSearchSection.defaultProps = {
  rootClassName: null,
  className: null,
  initialSearchFormValues: {},
};

LandingPageSearchSection.propTypes = {
  rootClassName: string,
  className: string,
  history: shape({
    push: func.isRequired,
  }),
  location: shape({
    search: string.isRequired,
  }),
};

export default LandingPageSearchSection;
