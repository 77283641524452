import React from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';

import { intlShape, injectIntl } from '../../../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../../../components';

import css from './LocationSearchForm.module.css';

const identity = v => v;

const LocationSearchFormComponent = props => {
  const handleChange = location => {
    if (location.selectedPlace) {
      props.onSubmit({ location });
    }
  };
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, intl } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        const preventFormSubmit = e => e.preventDefault();

        return (
          <Form className={classes} onSubmit={preventFormSubmit}>
            <Field
              name="location"
              format={identity}
              render={({ input, meta }) => {
                const { onChange, ...restInput } = input;
                const searchOnChange = value => {
                  onChange(value);
                  handleChange(value);
                };

                const searchInput = { ...restInput, onChange: searchOnChange };
                return (
                  <LocationAutocompleteInput
                    placeholder={'Search private ranches by state'}
                    iconClassName={css.searchInputIcon}
                    inputClassName={css.searchInput}
                    predictionsClassName={css.searchPredictions}
                    input={searchInput}
                    meta={meta}
                    className={css.container}
                    closeOnBlur={!isMobile}
                    isSearchable={false}
                  />
                );
              }}
            />
          </Form>
        );
      }}
    />
  );
};

LocationSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LocationSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LocationSearchForm = injectIntl(LocationSearchFormComponent);

export default LocationSearchForm;
